import { Button } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import { Box, Flex, Icon, useToast } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { FC, useContext } from 'react'
import isEqual from 'react-fast-compare'
import { IoMdEye } from 'react-icons/io'
import { useNavigate } from 'react-router'

import {
  QuestionnairePreviewModal,
  useCreateQuestionnaire,
  usePublishQuestionnaire,
} from '../../features/questionnaire'
import {
  EditorStoreProvider,
  QuestionnaireEditorStoreContext,
  useEditorStore,
} from './EditorStoreProvider'
import { ConfirmCancelDialog } from './components/ConfirmCancelDialog'
import { QuestionnaireEditor } from './components/QuestionnaireEditor'
import { QuestionnaireTitle } from './components/QuestionnaireTitle'
import { routes } from './routes'
import { EditorStoreState } from './useQuestionnaireEditorStore'

const select = (state: EditorStoreState) => ({
  canPublish: state.canPublish(),
})

const QAQuestionnaireCreatePageContent: FC = () => {
  const navigate = useNavigate()
  const toast = useToast()

  const { canPublish } = useEditorStore(select, (a, b) => isEqual(a, b))

  const [openConfirmDialog] = useModal(ConfirmCancelDialog)
  const [openQuestionnaireModal] = useModal(QuestionnairePreviewModal)
  const handlePreview = () => {
    if (!store) {
      return
    }
    const questionnaire = store.getState().questionnaire
    openQuestionnaireModal({
      questionnaire,
      showSubmitButton: false,
      isDisabled: false,
    })
  }

  const handleCancel = () => {
    navigate(routes.root)
  }
  const store = useContext(QuestionnaireEditorStoreContext)

  const { mutate: createQuestionnaire, isPending: isCreating } =
    useCreateQuestionnaire()
  const { mutate: publishQuestionnaire, isPending: isPublishing } =
    usePublishQuestionnaire()

  const handleSave = () => {
    if (!store) {
      return
    }
    const questionnaire = store.getState().questionnaire

    createQuestionnaire(questionnaire, {
      onSuccess: () => {
        toast({
          title: t`Questionnaire saved`,
          status: 'success',
          duration: 4000,
        })
        navigate(routes.root)
      },
      onError: (error) => {
        toast({
          title: t`Error saving questionnaire`,
          description: error.message,
          status: 'error',
          duration: 4000,
        })
      },
    })
  }

  const handlePublish = () => {
    if (!store) {
      return
    }
    const { questionnaire, canPublish } = store.getState()

    if (!canPublish()) {
      toast({
        title: t`Cannot publish questionnaire`,
        description: t`Please fix all validation errors before publishing`,
        status: 'error',
        duration: 4000,
      })
      return
    }

    // First create the questionnaire
    createQuestionnaire(questionnaire, {
      onSuccess: (createdQuestionnaire) => {
        // Then publish it
        publishQuestionnaire(createdQuestionnaire.uid, {
          onSuccess: () => {
            toast({
              title: t`Questionnaire published`,
              status: 'success',
              duration: 4000,
            })
            navigate(routes.root)
          },
          onError: (error) => {
            toast({
              title: t`Error publishing questionnaire`,
              description: error.message,
              status: 'error',
              duration: 4000,
            })
          },
        })
      },
      onError: (error) => {
        toast({
          title: t`Error creating questionnaire`,
          description: error.message,
          status: 'error',
          duration: 4000,
        })
      },
    })
  }

  return (
    <Box p={4} h="100%">
      <Flex justifyContent="flex-start" mb={8}>
        <QuestionnaireTitle />
      </Flex>

      <QuestionnaireEditor />

      <Flex gap={2} justifyContent="flex-start">
        <Button
          leftIcon={<Icon as={IoMdEye} />}
          variant="link"
          colorScheme="primary"
          onClick={handlePreview}
        >
          <Trans>Preview</Trans>
        </Button>
        <Button
          variant="outline"
          onClick={() =>
            openConfirmDialog({
              onConfirm: handleCancel,
            })
          }
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          onClick={handleSave}
          isLoading={isCreating && !isPublishing}
          isDisabled={isPublishing}
        >
          <Trans>Save as Draft</Trans>
        </Button>
        <Button
          primary
          onClick={handlePublish}
          isDisabled={!canPublish || isCreating}
          isLoading={isPublishing && !isCreating}
        >
          <Trans>Publish</Trans>
        </Button>
      </Flex>
    </Box>
  )
}

export const QAQuestionnaireCreatePage: FC = () => {
  return (
    <EditorStoreProvider>
      <QAQuestionnaireCreatePageContent />
    </EditorStoreProvider>
  )
}
