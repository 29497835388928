import { QuestionAnswer } from '@capturi/api-conversations'
import { Button } from '@capturi/ui-components'
import { Box, Center, Divider, Text, VStack } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { FC, useCallback, useMemo, useState } from 'react'

import { Guid, QaQuestionnaire, Question } from '../../types'
import { areAllQuestionsAnswered } from '../../utils/validation'
import { FreeTextPreview } from './FreeTextPreview'
import { RangePreview } from './RangePreview'
import { YesNoPreview } from './YesNoPreview'

const useQuestionnaireAnswers = ({
  initialAnswers,
}: {
  initialAnswers?: Record<Guid, QuestionAnswer>
}) => {
  const [answers, setAnswers] = useState<Record<Guid, QuestionAnswer>>(
    initialAnswers || {},
  )

  const setAnswerFor = useCallback(
    (questionUid: string, answer: QuestionAnswer) => {
      setAnswers((prev) => ({
        ...prev,
        [questionUid]: answer,
      }))
    },
    [],
  )

  const getAnswerFor = useCallback(
    (questionUid: string): QuestionAnswer | undefined => {
      return answers[questionUid]
    },
    [answers],
  )

  return { answers, setAnswerFor, getAnswerFor }
}

type QuestionnairePreviewProps = {
  questionnaire: QaQuestionnaire
  initialAnswers?: Record<Guid, QuestionAnswer>
  showSubmitButton?: boolean
  isDisabled?: boolean
  onSubmit?: (answers: Record<Guid, QuestionAnswer>) => void
}

export const QuestionnairePreview: FC<QuestionnairePreviewProps> = ({
  questionnaire,
  initialAnswers,
  showSubmitButton = true,
  isDisabled = false,
  onSubmit,
}) => {
  const { getAnswerFor, setAnswerFor, answers } = useQuestionnaireAnswers({
    initialAnswers,
  })

  // Define the renderQuestion callback before any early returns
  const renderQuestion = useCallback(
    (question: Question) => {
      const answer = getAnswerFor(question.uid)

      if (question.type === 'yesNo') {
        return (
          <YesNoPreview
            key={question.uid}
            question={question}
            answer={
              answer?.type === 'yesNo' || answer?.type === 'notRelevantYesNo'
                ? answer
                : undefined
            }
            onChange={(newAnswer) => setAnswerFor(question.uid, newAnswer)}
            renderSubquestion={renderQuestion}
            isDisabled={isDisabled}
          />
        )
      }

      if (question.type === 'range') {
        return (
          <RangePreview
            key={question.uid}
            question={question}
            answer={
              answer?.type === 'range' || answer?.type === 'notRelevantRange'
                ? answer
                : undefined
            }
            onChange={(newAnswer) => setAnswerFor(question.uid, newAnswer)}
            renderSubquestion={renderQuestion}
            isDisabled={isDisabled}
          />
        )
      }

      if (question.type === 'freeText') {
        return (
          <FreeTextPreview
            key={question.uid}
            question={question}
            answer={answer?.type === 'freeText' ? answer : undefined}
            onChange={(newAnswer) => setAnswerFor(question.uid, newAnswer)}
            isDisabled={isDisabled}
          />
        )
      }

      return null
    },
    [getAnswerFor, setAnswerFor, isDisabled],
  )

  // Check if all required questions are answered
  const canSubmit = useMemo(() => {
    return areAllQuestionsAnswered(questionnaire, answers)
  }, [questionnaire, answers])

  return (
    <Box mx="auto">
      <Text fontSize="sm" fontWeight="medium" mb={4} textColor="gray.600">
        {questionnaire.title}
      </Text>
      {questionnaire.sections.map((sec, idx) => (
        <Box key={sec.uid} mb={6}>
          <Text fontSize="sm" fontWeight="medium" mb={1}>
            {idx + 1}. {sec.title || t`Section title`}
          </Text>
          <Divider mb={2} />
          <VStack align="stretch" spacing={6}>
            {sec.questions.map((q) => renderQuestion(q))}
          </VStack>
        </Box>
      ))}
      {showSubmitButton && (
        <Center>
          <Button
            primary
            onClick={() => {
              onSubmit?.(answers)
            }}
            isDisabled={!canSubmit || isDisabled}
          >
            <Trans>Submit</Trans>
          </Button>
        </Center>
      )}
    </Box>
  )
}
