import {
  Editable,
  EditableInput,
  EditablePreview,
  FormControl,
  HStack,
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { FC, memo } from 'react'
import isEqual from 'react-fast-compare'

import { EditableControls } from 'pages/ScoringPage/editor/components/EditableControls'
import { useEditorStore } from '../EditorStoreProvider'
import { EditorStoreState } from '../useQuestionnaireEditorStore'

const selectTitle = (state: EditorStoreState) => ({
  title: state.questionnaire.title,
  setTitle: state.setTitle,
  missingTitle: state.validationErrors.missingTitle,
})

export const QuestionnaireTitle: FC = memo(() => {
  const { title, setTitle, missingTitle } = useEditorStore(
    selectTitle,
    (a, b) => isEqual(a, b),
  )

  return (
    <HStack gap={4}>
      <FormControl isInvalid={missingTitle}>
        <Editable
          fontSize="2xl"
          display="flex"
          alignItems="center"
          placeholder={t`Template name...`}
          value={title}
          onChange={setTitle}
        >
          <EditablePreview
            color={!title ? 'gray.400' : 'inherit'}
            whiteSpace="nowrap"
            overflow="hidden"
            maxW="500px"
          />
          <EditableInput autoFocus pl={2} size={40} />
          <EditableControls ml={4} />
        </Editable>
      </FormControl>
    </HStack>
  )
})
