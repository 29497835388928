import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { FC, memo, useCallback, useEffect } from 'react'
import isEqual from 'react-fast-compare'
import {
  MdAdd,
  MdCheckCircle,
  MdClose,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdStraighten,
  MdTextFields,
} from 'react-icons/md'

import { Guid, Question } from 'features/questionnaire'
import { useEditorStore } from '../EditorStoreProvider'
import { EditorStoreState } from '../useQuestionnaireEditorStore'
import { QuestionEditor } from './QuestionEditor'

import { IconType } from 'react-icons'

const MAX_LEVEL = 3

const iconTypeForQuestionType = (
  questionType: 'freeText' | 'yesNo' | 'range',
): IconType => {
  switch (questionType) {
    case 'freeText':
      return MdTextFields
    case 'yesNo':
      return MdCheckCircle
    case 'range':
      return MdStraighten
    default:
      return MdTextFields
  }
}

interface QuestionItemWithActionsProps {
  question: Question
  sectionUid: Guid
  level: number
  parentQuestionUid?: Guid
  isSelected: boolean
}

const QuestionItemWithActions: FC<QuestionItemWithActionsProps> = memo(
  ({ question, sectionUid, level, parentQuestionUid, isSelected }) => {
    const {
      setSelectedQuestion,
      reorderQuestion,
      addSubQuestion,
      validationErrors,
      canMoveQuestionUp,
      canMoveQuestionDown,
    } = useEditorStore(
      (state: EditorStoreState) => ({
        setSelectedQuestion: state.setSelectedQuestion,
        reorderQuestion: state.reorderQuestion,
        addSubQuestion: state.addSubQuestion,
        selectedQuestion: state.selectedQuestion,
        validationErrors: state.validationErrors,
        canMoveQuestionUp: state.canMoveQuestionUp(sectionUid, question.uid),
        canMoveQuestionDown: state.canMoveQuestionDown(
          sectionUid,
          question.uid,
        ),
      }),
      isEqual,
    )

    const hasEmptyTitleError = validationErrors.invalidQuestions.emptyTitle.has(
      question.uid,
    )
    const hasOutOfScaleError =
      validationErrors.invalidQuestions.outOfScaleCondition.has(question.uid)
    const hasError = hasEmptyTitleError || hasOutOfScaleError

    const handleSelect = () => {
      setSelectedQuestion({
        sectionUid,
        questionUid: question.uid,
        questionType: question.type,
        parentQuestionUid,
      })
    }

    // Only yesNo and range questions support subQuestions.
    const isYesNoOrRange =
      question.type === 'yesNo' || question.type === 'range'

    // These handlers for reordering the current element among its siblings are (for simplicity)
    // delegated to the store's reorderQuestion (the store knows about section ordering).
    const handleMoveUp = useCallback(() => {
      reorderQuestion(sectionUid, question.uid, 'up')
    }, [reorderQuestion, sectionUid, question.uid])
    const handleMoveDown = useCallback(() => {
      reorderQuestion(sectionUid, question.uid, 'down')
    }, [reorderQuestion, sectionUid, question.uid])

    return (
      <Box
        position="relative"
        borderColor={
          isSelected ? 'primary.500' : hasError ? 'danger' : 'border.light'
        }
        borderWidth={1}
        borderRadius="md"
        overflow="hidden"
      >
        <Flex
          align="flex-start"
          justify="space-between"
          p={2}
          gap={2}
          bg="gray.50"
          cursor="pointer"
          onClick={handleSelect}
          minH={14}
          // biome-ignore lint/a11y/useSemanticElements: need group here
          role="group"
          _hover={{
            bg: 'gray.100',
          }}
        >
          <Flex alignItems="center">
            <Icon as={iconTypeForQuestionType(question.type)} mr={2} />

            <Text fontSize="sm" flex="1">
              {question.title || t`Add your question`}
            </Text>
          </Flex>
          <Box
            alignItems="center"
            background="linear-gradient(270deg, #F5F5F5 86.02%, rgba(245, 245, 245, 0.00) 100%)"
            borderTopRightRadius="md"
            borderBottomRightRadius="md"
            display="flex"
            height="100%"
            opacity={0}
            transition="opacity 0.2s"
            position="absolute"
            right={0}
            top={0}
            px={2}
            _groupHover={{
              opacity: 1,
            }}
          >
            <HStack spacing={1}>
              {isYesNoOrRange && level < MAX_LEVEL && (
                <Menu placement="bottom-end" autoSelect={false} isLazy>
                  <Tooltip label={t`Add conditional question`} placement="top">
                    <MenuButton
                      as={IconButton}
                      size="xs"
                      aria-label={t`Add subQuestion`}
                      icon={<Icon as={MdAdd} />}
                      onClick={(e) => e.stopPropagation()}
                      borderColor={hasOutOfScaleError ? 'danger' : undefined}
                      borderWidth={hasOutOfScaleError ? 1 : undefined}
                    />
                  </Tooltip>
                  <Portal>
                    <MenuList>
                      <MenuItem
                        icon={<Icon as={MdCheckCircle} />}
                        onClick={(e) => {
                          e.stopPropagation()
                          addSubQuestion(sectionUid, question.uid, 'yesNo')
                        }}
                      >
                        <Trans>Yes/no question</Trans>
                      </MenuItem>
                      <MenuItem
                        icon={<Icon as={MdStraighten} />}
                        onClick={(e) => {
                          e.stopPropagation()
                          addSubQuestion(sectionUid, question.uid, 'range')
                        }}
                      >
                        <Trans>Number scale</Trans>
                      </MenuItem>
                      <MenuItem
                        icon={<Icon as={MdTextFields} />}
                        onClick={(e) => {
                          e.stopPropagation()
                          addSubQuestion(sectionUid, question.uid, 'freeText')
                        }}
                      >
                        <Trans>Free text</Trans>
                      </MenuItem>
                    </MenuList>
                  </Portal>
                </Menu>
              )}
              <VStack spacing={0}>
                <Tooltip label={t`Move up`} placement="top">
                  <IconButton
                    borderRadius={0}
                    size="2xs"
                    aria-label={t`Move up`}
                    icon={<Icon as={MdKeyboardArrowUp} />}
                    isDisabled={!canMoveQuestionUp}
                    onClick={(e) => {
                      e.stopPropagation()
                      handleMoveUp()
                    }}
                  />
                </Tooltip>
                <Tooltip label={t`Move down`} placement="top">
                  <IconButton
                    borderRadius={0}
                    size="2xs"
                    aria-label={t`Move down`}
                    icon={<Icon as={MdKeyboardArrowDown} />}
                    isDisabled={!canMoveQuestionDown}
                    onClick={(e) => {
                      e.stopPropagation()
                      handleMoveDown()
                    }}
                  />
                </Tooltip>
              </VStack>
            </HStack>
          </Box>
        </Flex>
      </Box>
    )
  },
)

interface SubQuestionGroupProps {
  group: {
    condition:
      | {
          type: 'yesNoAnswer'
          answer: 'Yes' | 'No'
        }
      | {
          type: 'rangeAnswer'
          answer: { min: number; max: number }
        }
      | {
          type: 'notRelevantYesNo'
        }
      | {
          type: 'notRelevantRange'
        }
    question: Question
  }
  sectionUid: Guid
  parentQuestionUid: Guid
  level: number
}

const SubQuestionGroup: FC<SubQuestionGroupProps> = memo(
  ({ group, sectionUid, parentQuestionUid, level }) => {
    let conditionText = t`N/A`
    let conditionColor = 'gray.600'
    if (group.condition.type === 'yesNoAnswer') {
      conditionText = group.condition.answer.toUpperCase()
      conditionColor =
        group.condition.answer === 'Yes'
          ? 'segments.quaternary.500'
          : 'segments.senary.500'
    } else if (group.condition.type === 'rangeAnswer') {
      conditionText = `${group.condition.answer.min}-${group.condition.answer.max}`
    }

    return (
      <Flex minW={10}>
        <HStack direction="row" align="center" gap={1} mr={2}>
          <Text
            textAlign="end"
            fontSize="xs"
            fontWeight="medium"
            color={conditionColor}
            minW={8}
          >
            {conditionText}
          </Text>
          <Box width="3px" bg={conditionColor} h="100%" />
        </HStack>
        <Box flex="1">
          <RecursiveQuestionList
            sectionUid={sectionUid}
            questions={[group.question]}
            level={level}
            parentQuestionUid={parentQuestionUid}
          />
        </Box>
      </Flex>
    )
  },
)

/* 
  Recursive component to render a list of questions with an added left margin for each level.
  This supports up to 3 levels (root: level 0, then level 1 and level 2).
*/
interface RecursiveQuestionListProps {
  sectionUid: Guid
  questions: Question[]
  level: number
  parentQuestionUid?: Guid
}

const RecursiveQuestionList: FC<RecursiveQuestionListProps> = memo(
  ({ sectionUid, questions, level, parentQuestionUid }) => {
    const { selectedQuestion } = useEditorStore(
      (state: EditorStoreState) => ({
        selectedQuestion: state.selectedQuestion,
      }),
      isEqual,
    )
    return (
      <VStack align="stretch" spacing={2}>
        {questions.map((question) => (
          <VStack key={question.uid} align="stretch" spacing={2}>
            <QuestionItemWithActions
              question={question}
              sectionUid={sectionUid}
              level={level}
              parentQuestionUid={parentQuestionUid}
              isSelected={selectedQuestion?.questionUid === question.uid}
            />
            {(question.type === 'yesNo' || question.type === 'range') &&
              (question.subQuestions ?? []).map((group, grpIndex) => (
                <SubQuestionGroup
                  key={`${question.uid}-${grpIndex}`}
                  group={group}
                  sectionUid={sectionUid}
                  parentQuestionUid={question.uid}
                  level={level + 1}
                />
              ))}
          </VStack>
        ))}
      </VStack>
    )
  },
)

/* --- Updated QuestionnaireSection that uses the recursive component --- */
export const QuestionnaireSection: FC<{
  sectionUid: Guid
  sectionIndex: number
}> = memo(({ sectionUid, sectionIndex }) => {
  const select = useCallback(
    (state: EditorStoreState) => ({
      selectedQuestion: state.selectedQuestion,
      addQuestion: state.addQuestion,
      setSectionTitle: state.setSectionTitle,
      removeSection: state.removeSection,
      section: state.questionnaire.sections.find((s) => s.uid === sectionUid),
      hasEmptyTitleError:
        state.validationErrors.invalidSections.emptyTitle.has(sectionUid),
      hasNoQuestionsError:
        state.validationErrors.invalidSections.noQuestions.has(sectionUid),
      canMoveSectionUp: state.canMoveSectionUp(sectionUid),
      canMoveSectionDown: state.canMoveSectionDown(sectionUid),
      reorderSection: state.reorderSection,
    }),
    [sectionUid],
  )
  const {
    section,
    hasEmptyTitleError,
    hasNoQuestionsError,
    addQuestion,
    setSectionTitle,
    removeSection,
    reorderSection,
    canMoveSectionUp,
    canMoveSectionDown,
  } = useEditorStore(select, isEqual)
  const handleRemoveSection = useCallback(() => {
    removeSection(sectionUid)
  }, [removeSection, sectionUid])

  const addYesNoQuestion = useCallback(() => {
    addQuestion(sectionUid, 'yesNo')
  }, [addQuestion, sectionUid])
  const addFreeTextQuestion = useCallback(() => {
    addQuestion(sectionUid, 'freeText')
  }, [addQuestion, sectionUid])
  const addRangeQuestion = useCallback(() => {
    addQuestion(sectionUid, 'range')
  }, [addQuestion, sectionUid])
  if (!section) return null
  return (
    <VStack align="stretch" spacing={2}>
      <HStack alignItems="start" overflowX="hidden">
        <VStack
          border="1px"
          borderColor={hasNoQuestionsError ? 'danger' : 'border.light'}
          borderRadius="lg"
          w="100%"
          align="stretch"
          gap={2}
          pl={3}
          pt={3}
          pr={2}
          pb={4}
        >
          <Flex justifyContent="space-between" alignItems="center">
            <FormControl isRequired isInvalid={hasEmptyTitleError}>
              <HStack h={12}>
                <Text fontSize="lg" fontWeight="medium">
                  {sectionIndex + 1}.
                </Text>
                <Tooltip
                  hasArrow
                  label={
                    hasEmptyTitleError ? (
                      <Trans>Section title cannot be empty</Trans>
                    ) : undefined
                  }
                >
                  <Input
                    autoFocus={true}
                    size="sm"
                    h="100%"
                    value={section.title}
                    onChange={(e) =>
                      setSectionTitle(section.uid, e.currentTarget.value)
                    }
                    placeholder={t`Add section name...`}
                    borderRadius="md"
                    fontSize="lg"
                    borderColor={hasEmptyTitleError ? 'danger' : undefined}
                  />
                </Tooltip>
                <Box h="100%">
                  <IconButton
                    aria-label={t`Remove section`}
                    icon={<Icon as={MdClose} />}
                    size="sm"
                    variant="ghost"
                    w="32px"
                    h="32px"
                    onClick={handleRemoveSection}
                  />
                </Box>
              </HStack>
            </FormControl>
          </Flex>
          {section.questions.length > 0 && <Divider ml={4} />}
          <VStack align="stretch" pl={5} spacing={2}>
            <RecursiveQuestionList
              sectionUid={section.uid}
              questions={section.questions}
              level={0}
            />
            <Flex gap={1} justifyContent="center">
              <Menu isLazy id="add-question-menu">
                <MenuButton as={Button} size="xs">
                  <Trans>Add question</Trans>
                </MenuButton>
                <MenuList>
                  <MenuItem
                    onClick={addYesNoQuestion}
                    icon={<Icon as={iconTypeForQuestionType('yesNo')} />}
                  >
                    <Trans>Yes/no question</Trans>
                  </MenuItem>
                  <MenuItem
                    onClick={addRangeQuestion}
                    icon={<Icon as={MdStraighten} />}
                  >
                    <Trans>Number scale</Trans>
                  </MenuItem>
                  <MenuItem
                    onClick={addFreeTextQuestion}
                    icon={<Icon as={iconTypeForQuestionType('freeText')} />}
                  >
                    <Trans>Free text</Trans>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </VStack>
        </VStack>
        <VStack gap={1}>
          <IconButton
            aria-label={t`Move up`}
            icon={<Icon as={MdKeyboardArrowUp} />}
            onClick={() => reorderSection(sectionUid, 'up')}
            size="sm"
            isDisabled={!canMoveSectionUp}
            variant="ghost"
          />
          <IconButton
            aria-label={t`Move down`}
            icon={<Icon as={MdKeyboardArrowDown} />}
            onClick={() => reorderSection(sectionUid, 'down')}
            isDisabled={!canMoveSectionDown}
            size="sm"
            variant="ghost"
          />
        </VStack>
      </HStack>
      {hasNoQuestionsError && (
        <Text fontSize="xs" color="danger">
          <Trans>* this section needs to contain at least one question.</Trans>
        </Text>
      )}
    </VStack>
  )
})

const listSelect = (state: EditorStoreState) =>
  state.questionnaire.sections.map((s) => s.uid)
export const QuestionnaireList: FC = () => {
  const sectionUids = useEditorStore(listSelect, isEqual)
  return (
    <VStack align="stretch" spacing={4} px={2}>
      {sectionUids.map((uid, index) => (
        <QuestionnaireSection key={uid} sectionUid={uid} sectionIndex={index} />
      ))}
    </VStack>
  )
}

const select = (state: EditorStoreState) => ({
  addSection: state.addSection,
  resetQuestionnaire: state.resetQuestionnaire,
})

export const QuestionnaireEditor: FC = () => {
  const { addSection, resetQuestionnaire } = useEditorStore(select, isEqual)

  useEffect(() => {
    resetQuestionnaire()
  }, [resetQuestionnaire])

  return (
    <Box>
      <Grid templateColumns="1fr 2fr" gap={0} h="calc(100vh - 200px)">
        {/* Left Column - Navigation */}
        <GridItem borderRight="1px" borderColor="border.light" overflowY="auto">
          <QuestionnaireList />
          <Flex justifyContent="center" mt={4}>
            <Button size="sm" onClick={addSection} mt={4}>
              <Trans>Add section</Trans>
            </Button>
          </Flex>
        </GridItem>
        {/* Middle Column - Question Editor */}
        <GridItem overflowY="auto">
          <QuestionEditor />
        </GridItem>
      </Grid>
    </Box>
  )
}
