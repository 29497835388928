import { Box, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { FC, ReactNode, useMemo } from 'react'

import { YesNoAnswer } from '@capturi/api-conversations'
import { Question, YesNoQuestion } from '../../types'
import { isYesNoConditionSatisfied } from '../../utils/conditions'

type YesNoPreviewProps = {
  question: YesNoQuestion
  answer: YesNoAnswer | undefined
  onChange: (newAnswer: YesNoAnswer) => void
  renderSubquestion: (subQuestion: Question) => ReactNode
  isDisabled?: boolean
}

const YesNoAnswerText = ({ answer }: { answer: YesNoAnswer | undefined }) => {
  if (!answer) return null

  switch (answer.type) {
    case 'notRelevantYesNo':
      return (
        <Text fontWeight="medium">
          <Trans>Not relevant</Trans>
        </Text>
      )
    case 'yesNo':
      if (answer.answer === 'Yes') {
        return (
          <Text fontWeight="medium" textColor="segmentQuaternary">
            <Trans>Yes</Trans>
          </Text>
        )
      }
      return (
        <Text fontWeight="medium" textColor="segmentSenary">
          <Trans>No</Trans>
        </Text>
      )
    default:
      return null
  }
}

export const YesNoPreview: FC<YesNoPreviewProps> = ({
  question,
  answer,
  onChange,
  renderSubquestion,
  isDisabled = false,
}) => {
  const questionsToShow = useMemo(
    () =>
      question.subQuestions.filter((group) =>
        isYesNoConditionSatisfied(group.condition, answer),
      ),
    [question.subQuestions, answer],
  )

  const handleChange = (val: string) => {
    if (isDisabled) return

    if (val === 'notRelevantYesNo') {
      onChange({ type: 'notRelevantYesNo' })
    } else {
      onChange({
        type: 'yesNo',
        answer: val as 'Yes' | 'No',
      })
    }
  }

  // Determine the current radio value based on the answer type
  const currentValue =
    answer?.type === 'notRelevantYesNo'
      ? 'notRelevantYesNo'
      : answer?.type === 'yesNo'
        ? answer.answer
        : ''

  return (
    <Box>
      <Text fontSize="sm">{question.title || t`Add your question`}</Text>
      {question.description && (
        <Text fontSize="sm" color="gray.600" mb={2} whiteSpace="pre-wrap">
          {question.description}
        </Text>
      )}
      {isDisabled ? (
        <YesNoAnswerText answer={answer} />
      ) : (
        <RadioGroup
          onChange={handleChange}
          value={currentValue}
          isDisabled={isDisabled}
        >
          <Stack direction="row">
            <Radio size="sm" value="Yes" variant="yesButton">
              <Trans>Yes</Trans>
            </Radio>
            <Radio size="sm" value="No" variant="noButton">
              <Trans>No</Trans>
            </Radio>
            {question.notRelevantOption && (
              <Radio
                size="sm"
                value="notRelevantYesNo"
                variant="notRelevantButton"
              >
                <Trans>Not relevant</Trans>
              </Radio>
            )}
          </Stack>
        </RadioGroup>
      )}

      {questionsToShow.map((group) => (
        <Box
          key={group.question.uid}
          mt={3}
          ml={4}
          pl={2}
          borderLeft="3px solid"
          borderColor="gray.200"
        >
          {renderSubquestion(group.question)}
        </Box>
      ))}
    </Box>
  )
}
