import { Box, Flex, Icon, useToast } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { FC, useCallback, useContext, useEffect } from 'react'
import isEqual from 'react-fast-compare'
import { useNavigate, useParams } from 'react-router'

import { Button } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import { IoMdEye } from 'react-icons/io'

import {
  EditorStoreProvider,
  QuestionnaireEditorStoreContext,
  useEditorStore,
} from './EditorStoreProvider'

import {
  QuestionnairePreviewModal,
  usePublishQuestionnaire,
  useQuestionnaire,
  useUpdateQuestionnaire,
} from '../../features/questionnaire'
import { ConfirmCancelDialog } from './components/ConfirmCancelDialog'
import { QuestionnaireEditor } from './components/QuestionnaireEditor'
import { QuestionnaireTitle } from './components/QuestionnaireTitle'
import { routes } from './routes'
import { EditorStoreState } from './useQuestionnaireEditorStore'

const select = (state: EditorStoreState) => ({
  canPublish: state.canPublish(),
  loadQuestionnaire: state.loadQuestionnaire,
})

const QAQuestionnaireEditPageContent: FC = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const { uid } = useParams<{ uid: string }>()
  const [openConfirmDialog] = useModal(ConfirmCancelDialog)

  const { canPublish, loadQuestionnaire } = useEditorStore(select, (a, b) =>
    isEqual(a, b),
  )

  const { data: fetchedQuestionnaire } = useQuestionnaire(uid)
  const { mutate: updateQuestionnaire, isPending: isUpdating } =
    useUpdateQuestionnaire()
  const { mutate: publishQuestionnaire, isPending: isPublishing } =
    usePublishQuestionnaire()

  useEffect(() => {
    if (fetchedQuestionnaire) {
      loadQuestionnaire(fetchedQuestionnaire)
    }
  }, [fetchedQuestionnaire, loadQuestionnaire])

  const handleCancel = () => {
    navigate(routes.root)
  }

  const store = useContext(QuestionnaireEditorStoreContext)
  const handleSave = useCallback(() => {
    if (!store || !uid) {
      return
    }

    const questionnaire = store.getState().questionnaire
    updateQuestionnaire(
      {
        uid,
        title: questionnaire.title,
        sections: questionnaire.sections,
      },
      {
        onSuccess: () => {
          toast({
            title: t`Questionnaire saved`,
            status: 'success',
            duration: 4000,
          })
          navigate(routes.root)
        },
        onError: (error) => {
          toast({
            title: t`Error saving questionnaire`,
            description: error.message,
            status: 'error',
            duration: 4000,
          })
        },
      },
    )
  }, [navigate, toast, uid, store, updateQuestionnaire])

  const [openQuestionnaireModal] = useModal(QuestionnairePreviewModal)
  const handlePreview = () => {
    if (!store) {
      return
    }
    const questionnaire = store.getState().questionnaire
    openQuestionnaireModal({
      questionnaire,
      showSubmitButton: false,
      isDisabled: false,
    })
  }

  const handlePublish = () => {
    if (!store || !uid) {
      return
    }
    const questionnaire = store.getState().questionnaire

    if (!canPublish) {
      toast({
        title: t`Cannot publish questionnaire`,
        description: t`Please fix all validation errors before publishing`,
        status: 'error',
        duration: 4000,
      })
      return
    }

    // First update the questionnaire with any changes
    updateQuestionnaire(
      {
        uid,
        title: questionnaire.title,
        sections: questionnaire.sections,
      },
      {
        onSuccess: () => {
          // Then publish it
          publishQuestionnaire(uid, {
            onSuccess: () => {
              toast({
                title: t`Questionnaire published`,
                status: 'success',
                duration: 4000,
              })
              navigate(routes.root)
            },
            onError: (error) => {
              toast({
                title: t`Error publishing questionnaire`,
                description: error.message,
                status: 'error',
                duration: 4000,
              })
            },
          })
        },
        onError: (error) => {
          toast({
            title: t`Error updating questionnaire`,
            description: error.message,
            status: 'error',
            duration: 4000,
          })
        },
      },
    )
  }

  return (
    <Box p={4} h="100%">
      <Flex justifyContent="flex-start" mb={8}>
        <QuestionnaireTitle />
      </Flex>

      <QuestionnaireEditor />

      <Flex gap={2} justifyContent="flex-start">
        <Button
          leftIcon={<Icon as={IoMdEye} />}
          variant="link"
          colorScheme="primary"
          onClick={handlePreview}
        >
          <Trans>Preview</Trans>
        </Button>
        <Button
          variant="outline"
          onClick={() =>
            openConfirmDialog({
              onConfirm: handleCancel,
            })
          }
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          onClick={handleSave}
          isLoading={isUpdating && !isPublishing}
          isDisabled={isPublishing}
        >
          <Trans>Save as Draft</Trans>
        </Button>
        <Button
          primary
          onClick={handlePublish}
          isDisabled={!canPublish || isUpdating}
          isLoading={isPublishing && !isUpdating}
        >
          <Trans>Publish</Trans>
        </Button>
      </Flex>
    </Box>
  )
}

export const QAQuestionnaireEditPage: FC = () => {
  return (
    <EditorStoreProvider>
      <QAQuestionnaireEditPageContent />
    </EditorStoreProvider>
  )
}
